import React, {useState, useEffect} from 'react'
import Footer from '../components/footer'
import Slider from '../components/slider.js';
import {slider_data, table_data, features_grid, customize_data, email_meet_chat_data} from '../utils/la1-data.js'
import {Grid} from '@material-ui/core';
import InputBox from '../components/la1/input-box.js';
import ContentGrid from '../components/la1/content-grid.js';
import ApplicationModal from '../components/la1/modal';
import AppBar from '../components/la1/top-bar'
import ComparisonTable from '../components/la1/comparison-table';
import { graphql, StaticQuery } from 'gatsby';
import '../styles/la1/la1.scss'

export default function Landing1()  {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [toggleChatEmail, setToggleChatEmail] = useState(0);
    const [paramsListUTM, setParamsListUTM] = useState(null);
    const [paramsListGen, setParamsListGen] = useState(null);

    useEffect(() => {
        let parameters;
        let valueUTM = '';
        let paramsListUTM = {};
        let paramsListGen = {}

        async function fetchData() {
            if(typeof window !== 'undefined') {
                parameters = window.location.search;
                if(parameters) {
                    parameters = parameters.split('&')
                    for (let i in parameters) {
                        if(parameters[i].includes('utm_sid')) {
                            valueUTM = parameters[i].split('=')[1];
                        }else if (parameters[i].includes('utm')) {
                            paramsListUTM[parameters[i].split('=')[0].replace('?', '')] = parameters[i].split('=')[1]
                        }else {
                            paramsListGen[parameters[i].split('=')[0].replace('?', '')] = parameters[i].split('=')[1]
                        }
                    }
                    paramsListUTM = sortParams(paramsListUTM);
                    setParamsListUTM(paramsListUTM)
                    setParamsListGen(paramsListGen)
                }
                
                if(!valueUTM) {
                    valueUTM = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
                }
                paramsListUTM['utm_sid'] = valueUTM;
                setParamsListUTM(paramsListUTM)
                const locale = getNavigatorLanguage();
                let raw = {event: "f_ad_landing_page_open", brand: "BX", locale, la_ver: '1'};
                let utm_params = {}
                
                for(let i in paramsListUTM) {
                    raw[i] = paramsListUTM[i]
                    utm_params[i] = paramsListUTM[i]
                }
                if (parameters){
                    for(let i in paramsListGen) {
                        raw[i] = paramsListGen[i]
                    }
                }
                
                raw.utm_params = utm_params;
    
                try {
                    await fetch(`${window.location.origin}/page-loaded`, {
                        method: 'POST',
                        headers : { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                           },
                        "body": JSON.stringify({raw})
                    });
                }catch(error) {
                    console.log(error)
                }
            }
        }
        fetchData();
        
    }, []);

    const sortParams = (list) => {
        let sortedParams = Object.keys(list)
        .sort()
        .reduce(function (acc, key) { 
            acc[key] = list[key];
            return acc;
        }, {});
        return sortedParams
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setValue(e.target.value)
        if(!e.target.value || validateEmail(value)) {
            setError(false)
        }
    }

    const getNavigatorLanguage = () => {
        if (navigator.languages && navigator.languages.length) {
          return navigator.languages[0];
        } else {
          return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'na';
        }
    }

    const handleSubmit = async (event) => {
        const locale = getNavigatorLanguage();
        event.preventDefault();
          
        if (!validateEmail(value)) {
          setError(true)
        }else {
          setError(false)
          let raw = {event: "f_ad_landing_page_submit_address", email: value, brand: "BX", locale, la_ver: '1' }
          let utm_params = {}
          let url = '';
  
          for(let i in paramsListUTM) {
              raw[i] = paramsListUTM[i];
              utm_params[i] = paramsListUTM[i];
              url += '&' + i + '=' + paramsListUTM[i]
          }

          if (paramsListGen) {
              for(let i in paramsListGen) {
                  raw[i] = paramsListGen[i]
                  url += '&' + i + '=' + paramsListGen[i]
              }
          }
          
          raw.utm_params = utm_params;
  
          var options = { 
              method: 'POST',
              headers: { 'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*' },
              body: JSON.stringify({ email: value })
          };
  
          let respone;
          let result;
  
          try {
              respone = await fetch(`${window.location.origin}/get-account-type`, options);
              result = await respone.json()
              if(result.type === 'gmail' || result.type === 'outlook' || result.type === 'office365') {
                  raw.account_type = await result.type.toString();
                  try {
                      await fetch(`${window.location.origin}/redirect-web`, {
                          method: 'POST',
                          headers : { 
                              'Content-Type': 'application/json',
                              'Accept': 'application/json'
                             },
                          "body": JSON.stringify({raw})
                      });
                  }catch(error) {
                      console.log(error)
                  }
                  window.location.replace(`https://web.blix.net?email=${value}&account_type=${result.type}${url}`)
              }else {
                  raw.account_type = 'other';
                  try {
                      await fetch(`${window.location.origin}/redirect-download`, {
                          method: 'POST',
                          headers : { 
                              'Content-Type': 'application/json',
                              'Accept': 'application/json'
                             },
                          "body": JSON.stringify({raw})
                      });
                  }catch(error) {
                      console.log(error)
                  }
                  raw.event = "f_ad_landing_page_download_redirect"
                  try {
                      await fetch(`${window.location.origin}/redirect-download`, {
                          method: 'POST',
                          headers : { 
                              'Content-Type': 'application/json',
                              'Accept': 'application/json'
                             },
                          "body": JSON.stringify({raw})
                      });
                  }catch(error) {
                      console.log(error)
                  }
                  window.location.replace(`/download?email=${value}&account_type=other${url}`)
                  
              }
          }catch(error) {
              console.log(error)
              raw.account_type = 'other'
                  try {
                      await fetch(`${window.location.origin}/redirect-download`, {
                          method: 'POST',
                          headers : { 
                              'Content-Type': 'application/json',
                              'Accept': 'application/json'
                          },
                          "body": JSON.stringify({raw})
                      });
                  }catch(error) {
                      console.log(error)
                  }
              raw.event = "f_ad_landing_page_download_redirect";
                  try {
                      await fetch(`${window.location.origin}/redirect-download`, {
                          method: 'POST',
                          headers : { 
                              'Content-Type': 'application/json',
                              'Accept': 'application/json'
                             },
                          "body": JSON.stringify({raw})
                      });
                  }catch(error) {
                      console.log(error)
                  }
              window.location.replace(`/download?email=${value}&account_type=other${url}`)
          }
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        
    
        <StaticQuery
        query={graphql`
        query LandingPageQuery {
          site {
            siteMetadata {
              title
              env
              imagesPrefix
            }
          }
        }
        `}
        render={data => (
            <div className="landing1-page">
            <AppBar handleOpen={handleOpen} />

            <div style={{padding: '0 15px', position: 'relative', height: '806px', background: 'radial-gradient(58.93% 58.93% at 47.97% 21.59%, #6CFFF6 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(77.17% 77.17% at 94.48% 14.39%, #D9E8FF 0%, rgba(103, 209, 255, 0) 100%)'}}>
                <h2 style={{ maxWidth: "1005px", margin: 'auto', paddingTop: '300px'}}>Boost your Team productivity today with Blix</h2>
                <InputBox handleChange={handleChange} handleSubmit={handleSubmit} error={error} />
            </div>

            <div className="control-section">
                <h2 style={{ maxWidth: "1020px", margin: 'auto'}}>Take control of your mailbox</h2>
                <p><strong>Blix</strong> - multi-platform universal email app, capable of managing an unlimited number of mail accounts from various providers, allowing for smart push notifications and group emailing while enabling personalization across multiple email accounts. </p>
            </div>

            <div className="business-opportunity-section">
                <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/op1.png`} alt="" />
                <h2>Never miss a business opportunity again</h2>
                <p>Paragraph about how you can miss an email if your inbox is not organized, or maybe you forgot to respond to couple of those which can lead to <strong>Financial/Performance/Productivity and Client Trust losses.</strong></p>
            </div>

            <div className="email-meet-chat-sec">
                <Grid container>
                    <Grid item lg={5} md={5} sm={12} >
                        <div className="email-meet-chat-left-pane">
                            <h2>When Email meets Chat</h2>
                            <p>Communicate better and faster within teams.
                                Create teams instantly, along with corresponding
                                channels for context-based communication.
                            </p>
                            <ContentGrid list={email_meet_chat_data} spacing={3} class={"email-meet-chat-left-pane-grid"} md={5} justify={"flex-start"} imagesPrefix={data.site.siteMetadata.imagesPrefix} />
                        </div>
                    </Grid>
                    <Grid item lg={7} md={7} sm={12}>
                        <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/op2_1.png`} alt="" />
                    </Grid>
                </Grid>
            </div>

            <div className="work-your-way-section">
                <Grid container spacing={2} justify="center">
                    <Grid item md={6} sm={12}>
                        <img src={toggleChatEmail === 1 ? `${data.site.siteMetadata.imagesPrefix}/landing1/image-9.png` : `${data.site.siteMetadata.imagesPrefix}/landing1/image-14.png`} alt="" style={{width: '100%'}} />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <div className="work-your-way-right-pane">
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <button className={toggleChatEmail === 0 ? 'active' : ''} onClick={() => setToggleChatEmail(0)}>
                                    <img src={toggleChatEmail === 0 ? `${data.site.siteMetadata.imagesPrefix}/landing1/Email-white.png` : `${data.site.siteMetadata.imagesPrefix}/landing1/002-email.png`} alt="" style={{marginRight: '10px'}} />
                                    Email
                                </button>
                                <button className={toggleChatEmail === 1 ? 'active' : ''} onClick={() => setToggleChatEmail(1)}>
                                    <img src={toggleChatEmail === 1 ? `${data.site.siteMetadata.imagesPrefix}/landing1/003-messenger.png` : `${data.site.siteMetadata.imagesPrefix}/landing1/003-messenger-purple.png`} alt="" style={{marginRight: '10px'}} />
                                    Chat
                                </button>
                            </div>
                            <div style={{maxWidth: '530px', padding: '0 10px'}}>
                                <h2>Work your way</h2>
                                <p>Give your team the ability to manage their projects in the way they work best and easily switch between views for ultimate flexibility</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className="input-box-section">
                <p className="input-box-item-title">Blix is the perfect replacement for your stock email app</p>
                <InputBox handleChange={handleChange} handleSubmit={handleSubmit} error={error} />
            </div>

            <div className="security-privacy-section">
                <div className="security-privacy-grid">
                    <div className="security-privacy-right-line" style={{height: '1px', background: '#CFE8FF' ,position: 'absolute', right: '-22.8%', width: '22.7%', top: '50%'}} />
                    <div className="security-privacy-grid-left-pane">
                        <div className="security-privacy-grid-left-pane-wrap">
                            <img className="left-pane-badge-image" src={`${data.site.siteMetadata.imagesPrefix}/landing1/Group-152.png`} alt="" />
                            <img className="left-pane-vector-image" src={`${data.site.siteMetadata.imagesPrefix}/landing1/Vector-10.png`} alt="" />
                            <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/tick-1.png`} alt="tick" style={{position: 'absolute', left: '15.4%', top: '36%'}} />
                        </div>
                        <div>
                            <ul>
                                <li>Total Privacy</li>
                                <li>Data Security</li>
                                <li>Data Management Tools</li>
                                <li>Full Transparency</li>
                            </ul>
                        </div>
                    </div>
                    <div className="security-privacy-grid-right-pane">
                        <h2>Security & Privacy guaranteed</h2>
                        <p>Paragraph about how we are managing your data and which technologies we are using to protect it.</p>
                        <a href="/">
                            Learn more
                            <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/Arrow-1.png`} alt="" style={{marginLeft: '8px'}} />
                        </a>
                    </div>
                    <div className="security-privacy-left-line" style={{height: '1px', background: '#CFE8FF' ,position: 'absolute', left: '-22.8%', width: '22.7%', top: '50%'}} />
                </div>
            </div>

            <div className="file-transfer-section">
                <h2>Unlimited file transfer capabilities</h2>
                <p>Paragraph about the benefits of the built-in file-transfer feature. Maybe provide some interesting specific use cases.</p>
                <InputBox handleChange={handleChange} class={"input-box"} handleSubmit={handleSubmit} error={error} />
            </div>

            <div className="customize-section">
                <h2>Highly customizable</h2>
                <p>With a wide range of visual and technical customizations, swipe actions and menus, action bars and buttons, colors and themes – Blix delivers powerful capabilites for your Team. Whether you go Pink or Blue. Go Blix.</p>
                <ContentGrid list={customize_data} class={"customize-grid"} imagesPrefix={data.site.siteMetadata.imagesPrefix} />
            </div>

            <div className="email-privider-section">
                <div className="email-privider-wrapper">
                    <h2>No matter what email provider you are using we got you covered</h2>
                    <p>Simple and quick login process, easy to manage/administer</p>
                    <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/Group-194.png`} alt="" />
                    <h5><span>Support for:</span> POP3, SMTP and IMAP.</h5>
                </div>
                <div className="linear-bg" />
            </div>

            <div className="blix-with-facebook-section">
                <Grid container justify="center" style={{height: '100%'}}>
                    <Grid item md={7} style={{height: '0'}}></Grid>
                    <Grid item lg={5} style={{height: '100%'}}>
                        <div className="blix-with-facebook-right-pane">
                            <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/startup 1.png`} alt="" style={{width: '109px', height: "109px"}} />
                            <h2>Small teams. Big projects.</h2>
                            <p>Paragraph about how we built <strong>Blix</strong> based on feedback from thousands of our users all around the world. How even small teams can achieve great results with <strong>Blix</strong>.</p>
                            <span>21% average improvement in team efficiency.</span>
                        </div>
                    </Grid>
                </Grid>
                
            </div>

            <div className="grow-business-section">
                <h2>Thousands of Users Grow Their Business with Blix</h2>
                <p>We’re constantly striving to provide a seamless, integrated experience to help you and your team work smarter and faster</p>
                <InputBox class={"input-box"} handleChange={handleChange} handleSubmit={handleSubmit} error={error} />
            </div>

            <ComparisonTable handleOpen={handleOpen} list={table_data} imagesPrefix={data.site.siteMetadata.imagesPrefix} />

            <div className="features-grid-section">
                <h2>More great features</h2>
                <ContentGrid list={features_grid} spacing={8} md={4} sm={12} imagesPrefix={data.site.siteMetadata.imagesPrefix} />
            </div>

            <div className="slider-section">
                <Slider list={slider_data} autoPlay={true} interval={5000} imagesPrefix={data.site.siteMetadata.imagesPrefix} />
            </div>
            

            <div className="news-image">
                <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/Group-150.png`} alt="" />
            </div>
            
            <div className="device-section">
                <div className="device-grid">
                    <Grid container justify="center">
                        <Grid item md={7} sm={12} style={{position: 'relative'}}>
                            <h2>Blix. Anywhere.<br /> Any Device. Any Browser.</h2>
                            <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/Group-176.png`} alt="platform icons" style={{width: '30%', position: 'absolute', right: '9%'}} />
                            <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/image-3.png`} alt="Blix Devices" />
                        </Grid>
                        <Grid item md={5} sm={12}>
                        <div className="input-box" style={{ float: 'right', background: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '33px 20px 20px 20px', maxWidth: '400px', border: '1px solid #CFE8FF', boxSizing: 'border-box', borderRadius: '6px'}}>
                            <span style={{maxWidth: '276px', marginBottom: '20px', textAlign: 'center'}}>Blix takes the best of BlueMail, our world class email service used by millions of people worldwide</span>
                            <img src={`${data.site.siteMetadata.imagesPrefix}/landing1/Group-151.png`} alt="" style={{maxWidth: '200px', marginBottom: '20px'}} />
                            <p style={{color: '#000000', fontSize: '14px', marginBottom: '50px'}}>Based on 634,901 reviews from <a href="https://play.google.com/store/apps/details?id=me.bluemail.mail" style={{color: '#000'}}>Google Play</a></p>
                            <span style={{fontSize: '16px'}}>Try Blix Today</span>
                            <input onChange={handleChange} type="text" placeholder="Enter Email" style={{width: '90%', marginTop: '20px', padding: '15px 17px', background: '#ECF7FF', border: '1px solid #FFFFFF', boxShadow: "inset 0px 4px 4px rgba(0, 52, 186, 0.25)", borderRadius: '5px'}} />
                            {error && <div style={{fontSize: '12px', color: 'red'}}>Please insert a correct email address</div>}
                            <button onClick={handleSubmit} style={{width: '250px', marginTop: '20px', background: '#1B23ED', boxShadow: '4px 4px 18px 1px rgba(27, 97, 237, 0.35)', borderRadius: '3px', padding: '17px 25px', border: 'none', color: '#fff', fontSize: '14px'}}>Start My 14-Day Free Trial</button>
                            <p style={{color: 'rgba(11, 104, 189, 0.63)', fontSize: '13px', marginTop: "11px"}}>No credit card required</p>
                        </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <ApplicationModal open={open} handleClose={handleClose} handleChange={handleChange} handleSubmit={handleSubmit} error={error} />
            <Footer/>
        </div>
        )}
        />
    );

}
