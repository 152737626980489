import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

export default function slider(props) {
  return (
    <div className="slider-carousel-wrap">
      <Carousel
        autoPlay={props.autoPlay}
        infiniteLoop
        showStatus={false}
        interval={props.interval}
        showArrows={false}
        showIndicators={false}
        showThumbs={false}
        swipeable={true}
      >
        {props.list.map((item, i) => {
          return (
            <div className="slider-carousel-content-wrap" key={i}>
              <img src={props.imagesPrefix + item.image} alt={item.title} />
              <div className="slider-carousel-content">
                <h4>{item.title}</h4>
                <h5>{item.subtitle1}</h5>
                <p>{item.subtitle2}</p>
                <span>{item.content}</span>
              </div>
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}
