import React from 'react';
import {Grid} from '@material-ui/core';

export default function ContentGrid(props) {
    return (
        <Grid container justify={props.justify || "center"} className={props.class} spacing={props.spacing}>
            {props.list.map((item, i) => {
                return <Grid item key={i} md={props.md} sm={props.sm}>
                <div>
                    {item.image && <img src={props.imagesPrefix + item.image} alt={item.title} />}
                    {item.title && <p>{item.title}</p>}
                    {item.content && <span>{item.content}</span>}
                </div>
            </Grid>
            })}
        </Grid>
    )
}