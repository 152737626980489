import React from "react"
import { Modal } from "@material-ui/core"
import InputBox from '../la1/input-box'

export default function AppModal(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="landing-modal">
        <InputBox handleChange={props.handleChange} handleSubmit={props.handleSubmit} error={props.error} />
      </div>
    </Modal>
  )
}
