import React from 'react';
import BlixIcon from '../../images/logo-blix.svg';

export default function AppBar(props) {
    return (
        <div className="top-fixed-bar">
            <BlixIcon style={{width: '137px', marginLeft: '15px'}} />
            <div style={{flexGrow: '1'}} />
            <button onClick={props.handleOpen}>Try For FREE</button>    
        </div>
    )
}