import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import BlixIcon from '../../images/logo-blix.svg';

export default function ComparisonTable(props) {
    return (
        <div className="comparison-table-section">
                <div className="comparison-table-wrapper">
                <h2>Comparison</h2>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Features</TableCell>
                                <TableCell><BlixIcon style={{width: '86px'}} /></TableCell>
                                <TableCell>Slack</TableCell>
                                <TableCell>Twist</TableCell>
                                <TableCell>Email1</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.list.map((item, i) => {
                                    return <TableRow key={i}>
                                    <TableCell component="th" scope="row">
                                    {item.name}
                                    </TableCell>
                                    <TableCell><img src={props.imagesPrefix + item.item1} alt={item.name} /></TableCell>
                                    <TableCell><img src={props.imagesPrefix + item.item2} alt={item.name} /></TableCell>
                                    <TableCell><img src={props.imagesPrefix + item.item3} alt={item.name} /></TableCell>
                                    <TableCell><img src={props.imagesPrefix + item.item4} alt={item.name} /></TableCell>
                                </TableRow>
                                })}
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Price per user
                                    </TableCell>
                                    <TableCell>$4.99 / month</TableCell>
                                    <TableCell>$6.67 / month</TableCell>
                                    <TableCell>$5 / month</TableCell>
                                    <TableCell>$7 / month</TableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                    <button onClick={props.handleOpen}>
                        Try Blix
                    </button>
                </div>
            </div>
    )
}