import React from 'react';

export default function InputBox(props) {
    return (
        <div className={"input-box-wrap " + props.class} >
            <span>Try Blix Today</span>
            <input type="text" placeholder="Enter Email" onChange={props.handleChange} />
            {props.error && <div style={{fontSize: '12px', color: 'red'}}>Please insert a correct email address</div>}
            <button onClick={props.handleSubmit}>Start My 14-Day Free Trial</button>
            <p>No credit card required</p>
        </div>
    )
}