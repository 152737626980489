import React from 'react';

export const slider_data = [
    {
        image: '/landing1/Ellipse-1.png',
        title: 'Anthony W.',
        subtitle1: 'Owner',
        subtitle2: 'Management Consulting',
        content: 'Review about how BlueMail helped ABC business to manage their communications.'
    },
    {
        image: '/landing1/Ellipse-2.png',
        title: 'Anthony W.',
        subtitle1: 'Owner',
        subtitle2: 'Management Consulting',
        content: 'Review about how BlueMail helped ABC business to manage their communications.'
    },
    {
        image: '/landing1/Ellipse-1.png',
        title: 'Anthony W.',
        subtitle1: 'Owner',
        subtitle2: 'Management Consulting',
        content: 'Review about how BlueMail helped ABC business to manage their communications.'
    },
];

export const table_data = [
    {
        name: 'Feature1',
        item1: "/landing1/Group-113.png",
        item2: "/landing1/Group-160.png",
        item3: "/landing1/Group-160.png",
        item4: "/landing1/Group-160.png"
    },
    {
        name: 'Feature2',
        item1: "/landing1/Group-113.png",
        item2: "/landing1/Group-113.png",
        item3: "/landing1/Group-113.png",
        item4: "/landing1/Group-113.png"
    },
    {
        name: 'Feature2',
        item1: "/landing1/Group-113.png",
        item2: "/landing1/Group-113.png",
        item3: "/landing1/Group-113.png",
        item4: "/landing1/Group-113.png"
    },
    {
        name: 'Feature2',
        item1: "/landing1/Group-113.png",
        item2: "/landing1/Group-160.png",
        item3: "/landing1/Group-160.png",
        item4: "/landing1/Group-160.png"
    },
    {
        name: 'Feature2',
        item1: "/landing1/Group-113.png",
        item2: "/landing1/Group-113.png",
        item3: "/landing1/Group-113.png",
        item4: "/landing1/Group-113.png"
    }
];

export const features_grid = [
    {
        image: "/landing1/alias2_0049 1.png",
        title: "Aliases",
        content: "Handle alternate email addresses pointing to your existing email account."
    },
    {
        image: "/landing1/offline0049-2_0049-1.png",
        title: "Usable offline",
        content: "With days to synchronize option – customized your offline needs."
    },
    {
        image: "/landing1/filter00492-2_0049-1.png",
        title: "Quick filters",
        content: "Easily filter emails by unread / starred"
    },
    {
        image: "/landing1/widget0049-2.png",
        title: "Scrolable & unread widgets",
        content: "Access your emails inbox at a glance from your home screen."
    },
    {
        image: "/landing1/dynamic-smartconver0049 2_0049-1.png",
        title: "Dynamic smart conversations",
        content: "Have a full visibility to the thread of linked emails."
    },
    {
        image: "/landing1/share2_0049-1.png",
        title: "Share",
        content: "Share emails publicly or privately through various social networks & messaging apps."
    },
    {
        image: "/landing1/done0049-3.png",
        title: "Get Stuff Done",
        content: "Quickly mark emails to be handled later and set reminders for them. Let Blue help turn your to-do list of emails into a do-done list."
    },
    {
        image: "/landing1/imail0057.svg",
        title: "iMail",
        content: "A hybrid of modern messaging with email is a real-time end-to-end secured email for teams enabling context-based conversations that are intuitive to anyone using chat today."
    },
    {
        image: "/landing1/easy2_0049-1.png",
        title: "Easy to configure",
        content: "Setup is an effortless breeze and by simply signing into your email accounts, your inbox will be ready and waiting for you."
    }
];

export const customize_data = [
    {
        image: '/landing1/moon-3.png',
        title: 'Dark theme'
    },
    {
        image: '/landing1/pencil-1.svg',
        title: 'Custom signature'
    },
    {
        image: '/landing1/favourite-contact-1.png',
        title: 'Avatars'
    },
    {
        image: '/landing1/instant2_0049-1.png',
        title: 'Lighting-fast notifications'
    }
];

export const email_meet_chat_data = [
    {
        image: '/landing1/004-email-1.png',
        title: 'Email integration',
        content: 'Receive external emails directly into your team chats with your Team Mailbox address.'
    },
    {
        image: '/landing1/002-secure-1.png',
        title: 'Secure',
        content: 'BlueChat is fully secure and your messages are always encrypted with End-to-end encryption (E2EE).'
    },
    {
        image: '/landing1/005-infinite-1.png',
        title: 'Continuity',
        content: 'Connect from anywhere and pick up right where you left off. BlueChat works great and syncs across all devices.'
    },
    {
        image: '/landing1/001-private-detective-1.png',
        title: 'Private',
        content: 'We take your privacy seriously and never give any third party access to your chats.'
    },
]